/*
 * @Descripttion : 办证订单
 * @version      :
 * @Author       : ml
 * @Date         : 2020-09-04 16:44:56
 * @LastEditors  : min
 * @LastEditTime : 2020-12-22 15:43:07
 */
import request from './request'

// 获取办证订单列表
export function getOrderList(data) {
	return request.fetch('certificationOrders', data)
}

// 更新办证订单
export function updateOrder(data) {
	return request.put('certificationOrders', data)
}

// 获取办证订单详情
export function getOrderByID(id) {
	return request.fetch('certificationOrders/' + id)
}

// 删除办证订单
export function delOrder(id) {
	return request.delete('certificationOrders/' + id)
}

// 内部审批
export function orderAudit(data) {
	return request.post('certificationOrders/audit', data)
}

// // 外部 下单 办证订单
// export function exteriorAddOrder(data) {
// 	return request.post('certificationOrders/exteriorAdd', data)
// }

// 外部审批
export function orderExteriorAudit(data) {
	return request.post('certificationOrders/exteriorAudit', data)
}

// 根据订单编号获取 办证订单信息
export function getOrderByOrderNumber(orderNumber) {
	return request.fetch('certificationOrders/orderNumber/' + orderNumber)
}

// 根据订单编号获取 办证订单信息 - 外部
export function getOrderExternalByOrderNumber(orderNumber) {
	return request.fetch('certificationOrders/externalDetails/' + orderNumber)
}

// 上传办证资料
export function uploadOrderFile(data) {
	return request.post('certificationOrders/upload', data)
}

// 手动派单
export function sendOrders(data) {
	return request.post('certificationOrders/sendorders', data)
}

// 跟进
export function followUp(data) {
	return request.post('certificationOrders/followUp', data)
}

// 根据商户电话获取 跟进记录
export function getByMerchantPhone(data) {
	return request.fetch('certificationFollowUpLog/getByMerchantPhone/' + data)
}

// 导出办证订单
export function downExport(data) {
	return request.fetch('certificationOrders/export', data)
}

// 完成
export function finish(data) {
	return request.post('certificationOrders/finish', data)
}

// 新增系统办证订单
export function addCertificationOrder(data) {
	data["json"] = true;
	return request.post('certificationOrders/system', data)
}

// 根据id获取系统办证订单
export function getCertificationOrder(id) {
	return request.fetch('certificationOrders/system/' + id)
}

// 修改系统办证订单
export function updateCertificationOrder(data) {
	data["json"] = true;
	return request.put('certificationOrders/system/', data)
}

// 获取备注列表
export function getRemarks(id) {
	return request.fetch('certificationOrders/' + id)
}

// 新增备注
export function addRemarks(data) {
	data["json"] = true;
	return request.post('certificationOrders/remarks', data)
}


// 获取订单-过户类型信息
export function getTransferType(id) {
	return request.fetch('/certificationOrders/' + id + '/transferType')
}

// 修改订单-过户类型
export function editTransferType(id, data) {
	return request.put('/certificationOrders/' + id + '/transferType', data)
}

// 获取订单-供应商信息
export function getSupplier(id) {
	return request.fetch('/certificationOrders/' + id + '/supplier')
}

// 修改订单-供应商
export function editSupplier(id, data) {
	return request.put('/certificationOrders/' + id + '/supplier', data)
}

// 获取办证订单的状态信息
export function getCertificationOrdersStatusInfo(id){
	return request.fetch('/certificationOrders/' + id + '/statusInfo')
}

// 修改订单信息
export function changeCertificationOrders(id,data) {
	return request.put('/certificationOrders/' + id + '/status',data)
}